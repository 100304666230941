




















































































































import Vue from "vue";
// import { defineComponent } from "vue";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";
import { AttendeeSearchResult } from "@/types/interfaces";

interface AttendeeListCompany {
    name: string;
    id: string;
    attendees: Array<AttendeeSearchResult>;
}

interface AttendeeListObject extends AttendeeSearchResult {
    name?: string;
}

interface LetterObject {
    letter: string;
    items: Array<AttendeeListCompany>;
}

const attendeeSearchStore = getModule(attendeeSearchVuexModule);

export default Vue.extend({
    data() {
        return {};
    },
    components: {
        PageTitleHeader,
        Spinners
    },
    computed: {
        pageOptions(): Record<string, any> {
            return store.getters.getPageOptions("attendees");
        },

        textHeader(): string {
            return this.pageOptions?.textHeader
                ? this.pageOptions.textHeader
                : "Full Attendee List";
        },

        useAttendeeListByCompany(): Record<string, string> {
            return this.pageOptions?.fullAttendeeListByCompany;
        },

        attendeeList(): Record<string, any> {
            if (attendeeSearchStore.attendeeList) {
                return attendeeSearchStore.attendeeList;
            } else {
                return [];
            }
        },

        preparedAttendeeList(): Record<string, any> {
            return this.attendeeList.map((attendee: any) => {
                const att = { ...attendee };

                if (
                    !att.companyName &&
                    this.pageOptions.useSchoolForCompanyName &&
                    att.school
                ) {
                    att.companyName = att.school;
                } else if (!att.companyName) {
                    att.companyName = "No Associated Organization";
                }

                if (!att.companyId) {
                    att.companyId = "N/A";
                }

                if (
                    this.pageOptions.addCountryToOrgName &&
                    att.companyName != "No Associated Organization" &&
                    att.country
                ) {
                    att.companyName = `${att.companyName}, ${att.country}`;
                    delete att.country;
                }

                return att;
            });
        },

        attendeeListByCompany(): Record<string, any> {
            const companies: Array<AttendeeListCompany> = [];

            this.preparedAttendeeList.forEach((att: AttendeeSearchResult) => {
                const companyListByName = Boolean(
                    this.pageOptions.companyListByName
                );

                const company = companyListByName
                    ? companies.find(
                          (item: AttendeeListCompany) =>
                              item.name === att.companyName
                      )
                    : companies.find(
                          (item: AttendeeListCompany) =>
                              item.id === att.companyId
                      );

                if (company) {
                    company.attendees.push(att);
                } else {
                    companies.push({
                        name: att.companyName,
                        id: att.companyId ? att.companyId : "",
                        attendees: [att]
                    });
                }
            });

            companies.sort((a: AttendeeListCompany, b: AttendeeListCompany) => {
                const aN = a.name.toLowerCase();
                const bN = b.name.toLowerCase();
                return aN < bN ? -1 : aN > bN ? 1 : 0;
            });

            return companies;
        },

        isLoaded(): boolean {
            return attendeeSearchStore.isAttendeeListLoaded;
        },

        alphabet(): Array<string> {
            const alpha = Array.from(Array(26)).map((e, i) => i + 65);
            return alpha.map((x) => String.fromCharCode(x));
        },

        itemsByLetter(): Array<LetterObject> {
            return this.alphabet
                .map((letter: string) => {
                    return {
                        letter: letter,
                        items: this.attendeeListByCompany.filter(
                            (item: any) => {
                                if (item.companyId) {
                                    return (
                                        item.name.toUpperCase().charAt(0) ===
                                        letter
                                    );
                                }
                            }
                        )
                    };
                })
                .filter((item: any) => item.items.length > 0);
        }
    },

    // /**
    //  * Lifecycle hooks
    //  */
    mounted() {
        if (!this.attendeeList.length) {
            attendeeSearchStore.getAttendeeList();
        }
    },
    methods: {
        /**
         * Methods
         */
        goToSearch() {
            this.$router.push("/attendees");
        },

        formatAttendeeListing(
            data: AttendeeListObject,
            omitCompanyName = false
        ) {
            const fields =
                Array.isArray(
                    store.getters.getPageOptions("attendees").attendeeListFields
                ) &&
                store.getters.getPageOptions("attendees").attendeeListFields
                    .length
                    ? store.getters.getPageOptions("attendees")
                          .attendeeListFields
                    : ["name", "companyName"];
            const nameFields =
                Array.isArray(
                    store.getters.getPageOptions("attendees")
                        .attendeeListNameFields
                ) &&
                store.getters.getPageOptions("attendees").attendeeListNameFields
                    .length
                    ? store.getters.getPageOptions("attendees")
                          .attendeeListNameFields
                    : ["firstName", "lastName"];

            const listingParts = [];
            const specialKeys = [
                "phone",
                "email",
                "prefix",
                "school",
                "attendeeId",
                "companyId"
            ];

            if (omitCompanyName) {
                specialKeys.push("companyName");
            }

            const nameParts: Array<string> = [];
            nameFields.forEach((field: keyof AttendeeListObject) => {
                const nameData = data[field];
                const fieldNotSuffix = Boolean(field != "suffix");

                if (
                    nameData &&
                    fieldNotSuffix &&
                    "string" === typeof nameData
                ) {
                    nameParts.push(nameData);
                }
            });

            let name = nameParts.join(" ");

            if (nameFields.includes("suffix") && data.suffix) {
                name += `, ${data.suffix}`;
            }

            name = `<strong>${name}</strong>`;

            if (data.attendeeId) {
                name = `<a href="/attendees/attendee-profile/${data.attendeeId}">${name}</a>`;
            }

            const otherFields = fields.filter(
                (key: string) => !specialKeys.includes(key)
            );

            otherFields.forEach((field: keyof AttendeeListObject) => {
                if (field == "name") {
                    listingParts.push(name);
                } else {
                    if (field in data) {
                        listingParts.push(data[field]);
                    }
                }
            });

            if (fields.includes("phone") && data.phone) {
                listingParts.push(`<b>Phone</b>: ${data.phone}`);
            }

            if (fields.includes("email") && data.email) {
                listingParts.push(
                    `<b>Email</b>: <a href="${data.email}">${data.email}</a>`
                );
            }

            return listingParts.filter((part) => part).join(", ");
        },

        scrollToFirstItemOfLetter(letter: string): void {
            const items = this.itemsByLetter.find(
                (item: any) => item.letter === letter
            );

            if (items && items.items.length) {
                console.log(items.items[0]);
                const id = `company-${items.items[0].id}`;
                const el = document.getElementById(id);

                if (el) {
                    const offset =
                        el.getBoundingClientRect().top + window.scrollY;
                    const header = document.querySelector<HTMLElement>(
                        ".app-header"
                    );
                    let headerOffset = 20;

                    if (header) {
                        headerOffset += header.offsetHeight;
                    }

                    window.scrollTo({
                        top: offset - headerOffset,
                        behavior: "smooth"
                    });
                }
            }
        }
    }
});
